import React from "react";
import "../styles/tailwind.css";
import "../styles/confirmStyles.scss";
import { Helmet } from "react-helmet";
import { Link } from "gatsby"
import { AnimatePresence, motion } from "framer-motion";

const confirmed = ({location}) => {
  /* const attendee = location.state.data.LastName */
  return (
      <>
      <Helmet>
      <title>Next.Legal | Confimation </title>
                <meta name="description" content="Next.legal is a showcase of innovations in legal tech and finance for the African ecosystem." />
                <meta name="keywords" content="legal tech, startup, law summit event" />
                <meta
                  name="robots"
                  content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                />
                <link rel="canonical" href="https://yourapp.com" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Next.Legal | Confirmation" />
                <meta property="og:description" content="Next.legal Confirmation page" />
                <meta property="og:url" content="https://next-legal.io" />
                <meta property="og:site_name" content="Next.Legal" />
                <meta property="og:image" content="../assets/img/icon-256x256.png" />
                <meta property="og:image:secure_url" content="https://next-legal.io" />
                <meta property="og:image:width" content="1280" />
                <meta property="og:image:height" content="720" />
                <meta name="twitter:card" content="../assets/img/icon-256x256.png" />
                <meta name="twitter:description" content="Next.legal Confirmation page" />
                <meta name="twitter:title" content="Next.Legal | Confirmation" />
                <meta name="twitter:image" content="../assets/img/icon-256x256.png" />
      </Helmet>
<AnimatePresence>
  
  <motion.div 
         animate={{y:0 , opacity: 1}}
         initial={{ y: 72, opacity: 0 }}
         transition={{
           duration: 1,
           ease: [0.6, 0.05, -0.01, 0.9],
         }}
  className="leading-loose p-10 confirm-container">
    <div className="max-w-xl m-4 p-10 bg-white rounded shadow-xl mx-auto form-style">
        <p className="text-gray-800 confirm-now">Thank you {/* <strong>{attendee}</strong> */} for registering for the Next.Legal Summit 2020.</p>
        <p className="text-gray-800 confirm-now last"><strong>When? </strong>This online event will air on October 23rd, 2020 at 10am-3:30pm CEST/SAST.</p>
        <p className="text-gray-800 confirm-now last"><strong>How to join? </strong>To access the event, <strong>please find the access link in the confirmation email.</strong>If you cannot find the email in your inbox, please check your spam/junk folder. if you have any other questions, please email us at: <span className="email">summit2@next-legal.io.</span></p>
        <Link
        to="/"
        >
        <button className="px-6 py-1 mt-5 text-white font-light tracking-wider bg-blue-600 hover:bg-blue-700 focus rounded-full">go home</button>
        </Link>
  </div>
</motion.div>
</AnimatePresence>
      </>
  )

}

export default confirmed;
